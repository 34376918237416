import { useFormik } from 'formik';
import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import CheckboxComponent from 'ui-kit-v2/checkbox/checkbox';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';
import * as Yup from 'yup';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { SetupAccountInputPassword } from 'display-components/setup-account-input-password/setup-account-input-password';
import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';
import { WorkflowFooterLinksItem } from 'components/workflow-footer-links/workflow-footer-links.props';

import { setRegistrationFormValues } from 'state/registration/registration.reducer';
import { registrationFormValuesSelector } from 'state/registration/registration.selector';

import { BREAKPOINTS } from 'const/breakpoints';

import { validatePassword } from 'schema/validation/password';

import { submitFormik } from 'util/form.helper';

import usePageRedirection from 'hooks/usePageRedirection';
import useRegistration from 'hooks/useRegistration';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './setup-your-account.style.scss';

interface SetupYourAccountPageProps {
    data: GatsbyTypes.SetupYourAccountPageDataQuery;
}

const SetupYourAccountPage = ({ data }: SetupYourAccountPageProps) => {
    useRegistration();

    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const formValues = useSelector(registrationFormValuesSelector);
    const { email } = formValues;

    const hasMembershipRedirection = usePageRedirection({
        featureFlag: ENABLE_MEMBERSHIP
    });

    const workflowFooterLinks =
        data?.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    const asideContent = {
        title: t('setupYourAccount.aside.title'),
        copyText: t('setupYourAccount.aside.subtitle'),
        bottomImage: data?.asideBottomImage
    };

    const initialValues = {
        firstName: formValues.firstName ?? '',
        lastName: formValues.lastName ?? '',
        password: '',
        isOver18: false,
        isNotEnrolledInGovernmentFundedInsurance: false
    };

    const setupYorAccountValidationSchema = Yup.object().shape({
        firstName: Yup.string().required(t('setupYourAccount.errors.firstName')),
        lastName: Yup.string().required(t('setupYourAccount.errors.lastName')),
        password: Yup.string()
            .required(t('setupYourAccount.errors.passwordRequired'))
            .test('password-strength', t('setupYourAccount.errors.passwordStrength'), function (value) {
                return validatePassword(value, this);
            }),
        isOver18: Yup.boolean().oneOf([true], t('setupYourAccount.errors.isOver18')).required(),
        isNotEnrolledInGovernmentFundedInsurance: Yup.boolean()
            .oneOf([true], t('setupYourAccount.errors.isNotEnrolledInGovernmentFundedInsurance'))
            .required()
    });

    const parseFormValues = (values: Record<string, any>) => ({
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        isGovernmentInsuranceEnrolled: !values.isNotEnrolledInGovernmentFundedInsurance,
        isAgeConfirmed: values.isOver18
    });

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: setupYorAccountValidationSchema,
        onSubmit: (values) => {
            dispatch(setRegistrationFormValues(parseFormValues(values)));
            navigate('/get-started-v2/health-benefits');
        }
    });

    const handleNavigation = () => {
        navigate(-1);
    };

    if (hasMembershipRedirection) {
        return <></>;
    }

    return (
        <WorkflowLayout
            className="setup-your-account-page"
            metaData={{ nodeTitle: 'Setup your account' }}
            isUnauthenticatedFlow={true}
        >
            <LayoutWrapperComponent
                title={t('setupYourAccount.title')}
                copyText={t('setupYourAccount.subtitle')}
                headerAlign="left"
                workflowNavigation={
                    <WorkflowNavigationSection
                        limit={3}
                        usage={1}
                        customColor="picton-blue"
                        handleClick={handleNavigation}
                    />
                }
                workflowLogo={width >= BREAKPOINTS.lg ? data?.workflowLogo : undefined}
                asideContent={asideContent}
                footerLinks={
                    <WorkflowFooterLinks
                        links={workflowFooterLinks as WorkflowFooterLinksItem[]}
                        locationGAflow="membership"
                    />
                }
            >
                <div className="setup-your-account-page__content">
                    <form className="setup-your-account-page__form" noValidate>
                        <TextInputComponent
                            {...formik.getFieldProps('firstName')}
                            name="firstName"
                            label={t('setupYourAccount.labels.firstName')}
                            variant={width > BREAKPOINTS.md ? 'alt' : 'default'}
                            error={formik.touched.firstName && formik.errors.firstName}
                            onlyLetters
                        />
                        <TextInputComponent
                            {...formik.getFieldProps('lastName')}
                            name="lastName"
                            label={t('setupYourAccount.labels.lastName')}
                            variant={width > BREAKPOINTS.md ? 'alt' : 'default'}
                            error={formik.touched.lastName && formik.errors.lastName}
                            onlyLetters
                        />

                        <ToastBox variant="primary" icon="info" iconColor="cathams-blue">
                            <span>
                                <Trans>{t('setupYourAccount.toasts.communicationInfo', { email })}</Trans>
                            </span>
                        </ToastBox>

                        <SetupAccountInputPassword
                            {...formik.getFieldProps('password')}
                            label={t('setupYourAccount.labels.password')}
                            isTouched={!!formik.touched.password}
                            error={
                                !!formik.touched.password &&
                                !!formik.errors.password &&
                                t('setupYourAccount.errors.password.required')
                            }
                            variant={width > BREAKPOINTS.md ? 'alt' : 'default'}
                            autoComplete="new-password"
                        />

                        <div className="setup-your-account-page__information_rules">
                            <CheckboxComponent
                                {...formik.getFieldProps('isOver18')}
                                label={t('setupYourAccount.labels.isOver18')}
                                hasError={!!formik.touched.isOver18 && !!formik.errors.isOver18}
                            />

                            {!!formik.touched.isOver18 && !!formik.errors.isOver18 && (
                                <ToastBox variant="danger" icon="warning">
                                    <span>
                                        <Trans>{t('setupYourAccount.toasts.isOver18')}</Trans>
                                    </span>
                                </ToastBox>
                            )}

                            <CheckboxComponent
                                {...formik.getFieldProps('isNotEnrolledInGovernmentFundedInsurance')}
                                hasError={
                                    !!formik.touched.isNotEnrolledInGovernmentFundedInsurance &&
                                    !!formik.errors.isNotEnrolledInGovernmentFundedInsurance
                                }
                                label={
                                    <span className="setup-your-account-page__is_not_enroll">
                                        <Trans>
                                            {t('setupYourAccount.labels.isNotEnrolledInGovernmentFundedInsurance')}
                                        </Trans>
                                    </span>
                                }
                            />

                            {!!formik.touched.isNotEnrolledInGovernmentFundedInsurance &&
                                !!formik.errors.isNotEnrolledInGovernmentFundedInsurance && (
                                    <ToastBox variant="danger" icon="warning">
                                        <span>
                                            {t('setupYourAccount.toasts.isNotEnrolledInGovernmentFundedInsurance')}
                                        </span>
                                    </ToastBox>
                                )}
                        </div>

                        <ButtonComponent
                            variant="solid"
                            color="primary"
                            label="Continue"
                            type="button"
                            onClick={() => submitFormik(formik)}
                            className="setup-your-account-page__submit_button"
                            isLoading={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                        />
                    </form>
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default SetupYourAccountPage;

export const query = graphql`
    query SetupYourAccountPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        asideBottomImage: file(relativePath: { eq: "assets/images/setup-your-account-image.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG, layout: CONSTRAINED, width: 360)
            }
        }
        workflowLogo: file(relativePath: { eq: "assets/images/birdi-logo-care.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG, layout: CONSTRAINED, width: 167)
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
