import React from 'react';
import Icon from 'ui-kit-v2/icon/icon';
import { IconProps } from 'ui-kit-v2/icon/icon';

import { ButtonProps } from './button.props';
import './button.styles.scss';

export const ButtonComponent = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        label,
        iconLeft,
        iconRight,
        children,
        disabled,
        isLoading,
        full,
        spaced,
        className,
        isVisible = true,
        size = 'md',
        variant = 'solid',
        color = 'primary',
        ...rest
    } = props;

    const buttonClassName = `
        button-component
        button-component--${variant}
        button-component--${color}
        ${isLoading ? 'button-component--loading' : ''}
        ${size === 'sm' ? 'button-component--sm' : size === 'lg' ? 'button-component--lg' : ''}
        ${full ? 'button-component--full' : ''}
        ${spaced ? 'button-component--spaced' : ''}
        ${className || ''}
    `;

    const iconColor = disabled
        ? 'silver'
        : variant === 'ghost'
        ? 'blue-lagoona'
        : color === 'destructive'
        ? 'white'
        : color === 'neutral'
        ? 'cathams-blue'
        : 'white';

    const renderIcon = (icon: React.ReactNode | IconProps | null) => {
        if (React.isValidElement(icon)) {
            return icon;
        } else if (typeof icon === 'object' && icon !== null && 'icon' in icon) {
            return <Icon {...(icon as IconProps)} color={iconColor} />;
        }
        return null;
    };

    if (!isVisible) return null;

    return (
        <button
            type={rest.type || 'button'}
            className={buttonClassName}
            ref={ref}
            {...rest}
            disabled={disabled || isLoading}
            aria-busy={isLoading}
            aria-label={isLoading ? 'Loading' : rest['aria-label'] || 'Button'}
            aria-live="polite"
        >
            {isLoading ? (
                <div className="button-component__spinner__container">
                    <Icon
                        icon="cog"
                        color={iconColor}
                        fill={iconColor}
                        width="34"
                        height="34"
                        viewBox="0 0 512 512"
                        className="button-component__spinner"
                    />
                </div>
            ) : (
                <>
                    {iconLeft && (
                        <span className="button-component__icon button-component__icon--left">
                            {renderIcon(iconLeft)}
                        </span>
                    )}
                    {label && <span className="button-component__text">{label}</span>}
                    {children && <span className="button-component__text">{children}</span>}
                    {iconRight && (
                        <span className="button-component__icon button-component__icon--right">
                            {renderIcon(iconRight)}
                        </span>
                    )}
                </>
            )}
        </button>
    );
});

export default ButtonComponent;
