import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import BirdiModal from 'components/birdi-modal/birdi-modal';
import CookieBanner from 'components/cookie-banner/cookie-banner.component';
import ModalComponent from 'components/modal/modal.component';
import SEO from 'components/seo/seo.component';
import { SEOProps } from 'components/seo/seo.props';

// State
import { accountIsLoggedInSelector, accountProfileSelector } from 'state/account/account.selectors';

// Util
import { InitOptimize } from 'util/google_optimize/optimize_helper';

// Hoc
import withBasicAuth from 'hoc/withBasicAuth';
import withSecurity from 'hoc/withSecurity';
import withSessionExpiration from 'hoc/withSessionExpiration';

import './workflow.layout.scss';

/**
 * WorkflowLayout - A base layout without menu navigation used for both authenticated and unauthenticated user flows.
 * It handles SEO, conditional navigation, and optimization initialization.
 *
 * @param {object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the layout.
 * @param {string} [props.className] - Optional additional CSS class for styling.
 * @param {SEOProps} [props.metaData] - Optional SEO metadata for setting up SEO tags.
 * @param {boolean} [props.isUnauthenticatedFlow] - Determines if the flow is for unauthenticated users.
 *
 * @example
 * <WorkflowLayout metaData={metaData} isUnauthenticatedFlow={false}>
 *    <LayoutWrapperComponent>Page content</LayoutWrapperComponent>
 * </WorkflowLayout>
 */
const WorkflowLayout = ({
    children,
    className,
    metaData,
    isUnauthenticatedFlow
}: {
    children: React.ReactNode;
    className?: string;
    metaData?: SEOProps;
    isUnauthenticatedFlow?: boolean;
}) => {
    const data = WorkflowBaseQuery();
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const profileObject = useSelector(accountProfileSelector);

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = data.allNodeApplicationPage.nodes.filter(
        (node: { path?: { alias?: string } }) => pageLocation === node.path?.alias?.toString()
    );

    useEffect(() => {
        InitOptimize();
    }, []);

    const classes = classNames('page', className);

    if (isUnauthenticatedFlow && isLoggedIn && profileObject) {
        navigate('/secure/medicine-cabinet');
        return null;
    }

    return (
        <div className={`base-workflow-layout ${classes}`}>
            {/* SEO Tags */}
            <SEO
                nodeTitle={metaData?.nodeTitle}
                title={metaData?.title}
                abstract={metaData?.abstract}
                description={metaData?.description}
                keywords={metaData?.keywords}
                applicationPageMetaData={applicationPageParagraphs[0]?.field_meta_tags}
            >
                {children}
            </SEO>

            <div className="base-workflow-layout--container">{children}</div>

            {/* Outside container components */}
            <BirdiModal />
            <ModalComponent />
            <CookieBanner />
        </div>
    );
};

export default withBasicAuth(withSessionExpiration(withSecurity(WorkflowLayout)));

export const WorkflowBaseQuery = () => {
    return useStaticQuery<any>(graphql`
        {
            allNodeApplicationPage {
                nodes {
                    path {
                        langcode
                        alias
                    }
                    field_meta_tags {
                        title
                        description
                        abstract
                        keywords
                    }
                }
            }
        }
    `);
};
