import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { registrationFormValuesSelector } from 'state/registration/registration.selector';

const useRegistration = () => {
    const formValues = useSelector(registrationFormValuesSelector);

    useEffect(() => {
        if (!formValues.zipCode || !formValues.email) {
            if (window.location.pathname !== '/get-started-v2/membership') {
                navigate('/get-started-v2/membership');
            }
        }

        if (!formValues.firstName || !formValues.lastName || !formValues.password) {
            if (window.location.pathname !== '/get-started-v2/setup-your-account') {
                navigate('/get-started-v2/setup-your-account');
            }
        }
    }, [formValues]);
};

export default useRegistration;
