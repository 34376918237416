import classNames from 'classnames';
import React, { ChangeEvent, useCallback } from 'react';
import { Form as ReactForm } from 'react-bootstrap';

import { CheckboxProps } from './checkbox.props';
import './checkbox.styles.scss';

const CheckboxComponent = (props: CheckboxProps) => {
    const { id, name, value, label, isSelected, disabled, hasError, onChange } = props;
    const checkboxId = id || `${name}-${value}`;

    const mainClass = classNames('checkbox-component', {
        'checkbox--error': hasError,
        'checkbox--disabled': disabled
    });

    const containerClass = classNames('checkbox-component--container', { highlight: props.isSelected });

    const handleLabelClick = useCallback(() => {
        const checkboxInput = document.getElementById(checkboxId) as HTMLInputElement;
        if (checkboxInput && !disabled) {
            checkboxInput.click();
        }
    }, [name, value, disabled, checkboxId]);

    const handleLabelKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if ((event.key === 'Enter' || event.key === ' ') && !disabled) {
                event.preventDefault();
                handleLabelClick();
            }
        },
        [handleLabelClick, disabled]
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
    };

    return (
        <div className={mainClass}>
            <div role="presentation" className={containerClass} onKeyDown={handleLabelKeyPress}>
                <ReactForm.Check
                    type="checkbox"
                    className="checkbox-component--input"
                    disabled={disabled}
                    isInvalid={hasError}
                    label={label || 'checkbox'}
                    title={label || 'checkbox'}
                >
                    <ReactForm.Check.Input
                        id={checkboxId}
                        type="checkbox"
                        name={name}
                        value={value}
                        checked={isSelected}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                    <ReactForm.Check.Label htmlFor={checkboxId}>{label}</ReactForm.Check.Label>
                </ReactForm.Check>
            </div>
        </div>
    );
};

export default CheckboxComponent;
