import { RootState } from 'state/store';

// main selector
export const RegistrationSelector = (state: RootState) => state.registrationReducer;

// useful selectors
export const registrationContinueRegistrationSelector = (state: RootState) => state.registrationReducer;
export const registrationFormValuesSelector = (state: RootState) => state.registrationReducer.formValues;
export const registrationIsLoadingSelector = (state: RootState) => {
    return state.registrationReducer.isLoading || false;
};
