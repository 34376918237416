import { FormikProps } from 'formik';

export const submitFormik = async <T>(formik: FormikProps<T>, skipFieldsForNotFocusWhenError?: string[]) => {
    try {
        formik.setTouched(Object.keys(formik.values!).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
        const errors = await formik.validateForm();

        if (Object.keys(errors).length > 0) {
            const errorFields: HTMLElement[] = [];

            for (const key in errors) {
                const field = document.querySelector(
                    `[name="${key}"], [data-field-name="${key}"]`
                ) as HTMLElement | null;
                if (field) {
                    errorFields.push(field);
                }
            }

            const wrapperErrorElements = Array.from(
                document.querySelectorAll('.text-input-component__wrapper--error')
            ) as HTMLElement[];

            wrapperErrorElements.forEach((wrapper) => {
                const inputField = wrapper.querySelector('input') as HTMLElement | null;
                if (inputField && !skipFieldsForNotFocusWhenError?.includes(inputField.getAttribute('name') || '')) {
                    errorFields.push(inputField);
                }
            });

            errorFields.push(...wrapperErrorElements);

            errorFields.sort((a, b) => (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? 1 : -1));

            for (const field of errorFields) {
                field.scrollIntoView({ behavior: 'smooth', block: 'center' });
                field.focus();
            }
        } else {
            formik.handleSubmit();
        }
    } catch (e) {
        console.error('formik and formik.values are required');
        console.error(e);
    }
};
