import useWindowDimensions from './useWindowDimensions';

type LayoutType = 'large' | 'medium' | 'small';

const useLayoutDimensions = (): LayoutType => {
    const { width } = useWindowDimensions();

    if (width >= 1280) {
        return 'large';
    } else if (width >= 960 && width < 1280) {
        return 'medium';
    } else {
        return 'small';
    }
};

export default useLayoutDimensions;
