import classNames from 'classnames';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import useLayoutDimensions from 'hooks/useLayoutDimensions';

import { LayoutWrapperProps } from './layout-wrapper.props';
import './layout-wrapper.styles.scss';

/**
 * LayoutWrapperComponent - A flexible layout wrapper that adjusts based on screen size, with support for logos,
 * navigation, aside content, and footer links. Used for the new simplified registration flows
 *
 * @param {ImageDataLike} [props.workflowLogo] - Optional image data for the workflow logo.
 * @param {ImageDataLike} [props.asideContent.bottomImage] - Optional image at the bottom of the aside section.
 * @param {ReactElement} [props.workflowNavigation] - Optional navigation component for the workflow section.
 * @param {ImageDataLike} [props.birdiAddonImage] - Optional image to display before the main content.
 *
 * @returns {JSX.Element} Returns the structured layout with an optional aside for large layouts, headers, and content.
 *
 * @example
 * <LayoutWrapperComponent
 *    title="Welcome to Workflow"
 *    copyText="This is the main content"
 *    workflowLogo={workflowLogo}
 *    asideContent={{
 *        title: 'Aside Section',
 *        copyText: 'Additional information here',
 *        bottomImage: asideBottomImage,
 *    }}
 *    footerLinks={<FooterLinksComponent />}
 * >
 *   <div>
 *      <p>Main content goes here</p>
 *   </div>
 * </LayoutWrapperComponent>
 */

const LayoutWrapperComponent = ({
    children,
    className,
    title,
    asideContent,
    copyText,
    headerAlign = 'center',
    workflowLogo,
    workflowNavigation,
    footerLinks,
    birdiAddonImage,
    isLogoFixed = false
}: LayoutWrapperProps) => {
    const { title: asideTitle, copyText: asideCopyText, bottomImage } = asideContent || {};

    const layout = useLayoutDimensions();
    const isLargeLayout = layout === 'large';

    const contentClass = classNames('layout-wrapper-content', className);
    const innerHeaderClass = classNames('content__header', {
        'header-left': headerAlign === 'left',
        'header-center': headerAlign === 'center',
        'header-right': headerAlign === 'right',
        hasBirdiAddon: !!birdiAddonImage
    });
    const sectionContentClass = classNames('section__content', {
        hasWorkflowNav: !!workflowNavigation
    });

    return (
        <main className={contentClass}>
            {asideContent && isLargeLayout && (
                <aside>
                    <div className="layout-wrapper-content__aside">
                        {workflowLogo && (
                            <div className="aside__brand">
                                <GatsbyImage image={getImage(workflowLogo) as IGatsbyImageData} alt="Birdi + Care" />
                            </div>
                        )}
                        <div className="aside__copy">
                            <span>{asideTitle}</span>
                            <p>{asideCopyText}</p>
                        </div>
                    </div>

                    {bottomImage && (
                        <div className="layout-wrapper-content__bottom-image">
                            <GatsbyImage image={getImage(bottomImage) as IGatsbyImageData} alt={asideTitle as string} />
                        </div>
                    )}
                </aside>
            )}
            <section>
                <div className="layout-wrapper-content__section">
                    <div className="section__top-area">
                        {((!isLargeLayout && !workflowNavigation) || isLogoFixed) && workflowLogo && (
                            <div className="top-area__logo">
                                <GatsbyImage image={getImage(workflowLogo) as IGatsbyImageData} alt="Birdi + Care" />
                            </div>
                        )}
                        {workflowNavigation && <div className="top-area__navigation">{workflowNavigation}</div>}
                    </div>

                    <div className={sectionContentClass}>
                        {title && (
                            <div className={innerHeaderClass}>
                                <h1>{title}</h1>
                                {copyText && <p>{copyText}</p>}
                            </div>
                        )}

                        <div className="content__children">
                            <>
                                {birdiAddonImage && (
                                    <div className="before-content">
                                        <GatsbyImage
                                            image={getImage(birdiAddonImage) as IGatsbyImageData}
                                            alt={'first-step-icon'}
                                        />
                                    </div>
                                )}
                                {children}
                            </>
                        </div>
                    </div>
                </div>

                {footerLinks && <div className="layout-wrapper-content__footer-links">{footerLinks}</div>}
            </section>
        </main>
    );
};

export default LayoutWrapperComponent;
