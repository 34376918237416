import classNames from 'classnames';
import React from 'react';

import { ProgressBarProps } from './progress-bar.props';
import './progress-bar.style.scss';

const definePercentageOfUsage = (limit: number, usage: number) => {
    return (usage / limit) * 100;
};

const handlePercentageStyle = (limit: number, usage: number, withColors?: boolean) => {
    const percentageOfUsage = definePercentageOfUsage(limit, usage);

    if (withColors) {
        if (percentageOfUsage <= 25) {
            return 'puerto-rico';
        } else if (percentageOfUsage <= 49) {
            return 'mustard';
        } else if (percentageOfUsage <= 75) {
            return 'mandarin';
        } else {
            return 'flamingo';
        }
    } else {
        return 'cerulean';
    }
};

const ProgressBar = (props: ProgressBarProps) => {
    const { limit, usage, customColor, label, labelPosition, showUsageColorsByPercentage = false } = props;

    const progressBarClass = classNames('progress-bar-component', {
        [`label-${labelPosition}`]: label
    });

    const colorClass =
        customColor !== undefined
            ? `custom-color-${customColor}`
            : `custom-color-${handlePercentageStyle(limit, usage, showUsageColorsByPercentage)}`;

    return (
        <div className={progressBarClass} aria-describedby="progress-bar">
            {label && (
                <label className="progress-bar-label" htmlFor="progress-bar">
                    {label}
                </label>
            )}
            <progress id="progress-bar-component" className={colorClass} max={limit} value={usage} />
        </div>
    );
};

export default ProgressBar;
