import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import Icon from 'ui-kit-v2/icon/icon';
import { v4 as uuidv4 } from 'uuid';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { TextInputProps } from './text-input.props';
import './text-input.styles.scss';

const TextInputComponent = forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            id,
            label,
            value,
            onChange,
            onBlur,
            icon,
            error,
            success,
            contentLeft,
            contentRight,
            type,
            tooltip,
            readOnly,
            onlyNumbers,
            onlyLetters,
            className,
            disabled = false,
            variant = 'default',
            onKeyUp,
            ...props
        },
        ref
    ) => {
        const MIN_INPUT_WIDTH_TO_SHOW_WARNING_ICON = 160;
        const [focused, setFocused] = useState(false);
        const [filled, setFilled] = useState(false);
        const [showWarningIcon, setShowWarningIcon] = useState(false);
        const inputId = useMemo(() => id || uuidv4(), [id]);
        const { width } = useWindowDimensions();

        useEffect(() => {
            const parsedValue = String(value).trim();
            setFilled(parsedValue !== undefined && parsedValue !== null && parsedValue !== '');
        }, [value]);

        useEffect(() => {
            const checkInputWidth = () => {
                const inputElement = document.getElementById(inputId) as HTMLElement | null;
                if (inputElement) {
                    const inputWidth = inputElement.offsetWidth;
                    setShowWarningIcon(inputWidth >= MIN_INPUT_WIDTH_TO_SHOW_WARNING_ICON);
                }
            };

            checkInputWidth();
            window.addEventListener('resize', checkInputWidth);
            return () => window.removeEventListener('resize', checkInputWidth);
        }, [inputId, width]);

        const handleChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                let newValue = event.target.value;

                if (onlyNumbers) {
                    newValue = newValue.replace(/\D/g, '');
                }

                if (onlyLetters) {
                    newValue = newValue.replace(/[^a-zA-Z\s]/g, '');
                }

                event.target.value = newValue;
                onChange?.(event);
            },
            [onChange, onlyNumbers, onlyLetters]
        );

        const handleBlur = useCallback(
            (event: React.FocusEvent<HTMLInputElement>) => {
                setFocused(false);
                onBlur?.(event);
                setFilled(value !== undefined && value !== null && value !== '');
            },
            [onBlur, value]
        );

        const handleKeyUp = useCallback(
            (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (onKeyUp) {
                    onKeyUp?.(event);
                    setFocused(false);
                    setFilled(value !== undefined && value !== null && value !== '');
                }
            },
            [onKeyUp, value]
        );

        const handleContentLeftClick = useCallback(
            (event: React.MouseEvent<HTMLButtonElement>) => {
                contentLeft?.onClick?.(event);
                event.preventDefault();
                event.currentTarget.blur();
            },
            [contentLeft]
        );

        const handleAnimationStart = useCallback(
            (event: React.AnimationEvent<HTMLInputElement>) => {
                const inputElement = event.target as HTMLInputElement;

                if (event.animationName === 'autofill') {
                    let newValue = inputElement.value;

                    if (onlyNumbers) {
                        newValue = newValue.replace(/\D/g, '');
                    }

                    if (onlyLetters) {
                        newValue = newValue.replace(/[^a-zA-Z]/g, '');
                    }

                    inputElement.value = newValue.trim();

                    const syntheticEvent = new Event('input', { bubbles: true });
                    inputElement.dispatchEvent(syntheticEvent as Event & { target: HTMLInputElement });
                }
            },
            [onlyNumbers, onlyLetters]
        );

        const memoizedContentLeft = useMemo(() => {
            if (!contentLeft) return null;

            return (
                <button
                    tabIndex={0}
                    className="text-input-component__content-left"
                    style={contentLeft.onClick ? { cursor: 'pointer' } : undefined}
                    onClick={handleContentLeftClick}
                    aria-label={contentLeft.onClick && 'Button action click'}
                >
                    {contentLeft.children}
                    {contentLeft.tooltip && (
                        <span className="text-input-component__content-left__tooltip">{contentLeft.tooltip}</span>
                    )}
                </button>
            );
        }, [contentLeft, handleContentLeftClick]);

        const labelClassName = useMemo(() => {
            return `text-input-component__label ${focused || filled ? 'text-input-component__label--active' : ''}
            ${contentLeft ? 'text-input-component__label--with-left-content' : ''}
            ${error ? 'text-input-component__label--with-error' : ''}`;
        }, [focused, filled, contentLeft, error]);

        const inputClassName = useMemo(() => {
            return `text-input-component__input ${focused || filled ? 'text-input-component__input--filled' : ''} ${
                contentLeft ? 'text-input-component__input--with-left-content' : ''
            } `;
        }, [focused, filled, contentLeft]);

        return (
            <div
                className={`text-input-component ${disabled || readOnly ? 'text-input-component--disabled' : ''} ${
                    !label ? 'text-input-component--no-label' : ''
                } `}
            >
                <div
                    className={`text-input-component__wrapper ${
                        variant === 'alt' ? 'text-input-component__wrapper--alt' : ''
                    } ${error ? 'text-input-component__wrapper--error' : ''} ${
                        success ? 'text-input-component__wrapper--success' : ''
                    } ${readOnly ? 'text-input-component__wrapper--readonly' : ''}
                    ${className || ''}`}
                >
                    {memoizedContentLeft}
                    <input
                        id={inputId}
                        ref={ref}
                        type={type || 'text'}
                        value={value}
                        onChange={!readOnly ? handleChange : undefined}
                        onFocus={() => setFocused(true)}
                        onBlur={handleBlur}
                        onKeyUp={handleKeyUp}
                        aria-invalid={!!error}
                        aria-describedby={error ? `${inputId}-error` : `${inputId}-success`}
                        aria-disabled={disabled || readOnly}
                        aria-readonly={readOnly}
                        disabled={disabled}
                        readOnly={readOnly}
                        onAnimationStart={handleAnimationStart}
                        {...props}
                        className={inputClassName}
                    />
                    {label && (
                        <label className={labelClassName} htmlFor={inputId}>
                            {label}
                        </label>
                    )}

                    {error && showWarningIcon && (
                        <div
                            className="text-input-component__error-icon"
                            style={tooltip ? { cursor: 'pointer' } : undefined}
                            aria-describedby={`${inputId}-error`}
                        >
                            <span className="text-input-component__error-icon__icon">
                                <Icon icon="warning" color="deep-chestnut" />
                            </span>
                            {tooltip && <span className="text-input-component__error-icon__tooltip">{tooltip}</span>}
                        </div>
                    )}

                    {success && !error && (
                        <div
                            className="text-input-component__success-icon"
                            style={tooltip ? { cursor: 'pointer' } : undefined}
                            aria-describedby={`${inputId}-success`}
                        >
                            <span className="text-input-component__error-icon__icon">
                                <Icon icon="checkmark" color="puerto-rico" />
                            </span>
                            {tooltip && <span className="text-input-component__error-icon__tooltip">{tooltip}</span>}
                        </div>
                    )}

                    {contentRight && (
                        <button
                            tabIndex={0}
                            className="text-input-component__content-right"
                            style={contentRight.onClick ? { cursor: 'pointer' } : undefined}
                            onClick={(event) => {
                                contentRight.onClick?.(event);
                                event.preventDefault();
                                event.currentTarget.blur();
                            }}
                            aria-label={contentRight.onClick && 'Button action click'}
                        >
                            {contentRight.children}
                            {contentRight.tooltip && (
                                <span className="text-input-component__content-right__tooltip">
                                    {contentRight.tooltip}
                                </span>
                            )}
                        </button>
                    )}
                </div>
                {error && (
                    <div className="text-input-component__info-container">
                        <small
                            id={`${inputId}-error`}
                            className={`text-input-component__hint ${error ? 'text-input-component__hint--error' : ''}`}
                            role="alert"
                            aria-live="assertive"
                        >
                            {error}
                        </small>
                    </div>
                )}
            </div>
        );
    }
);

export default TextInputComponent;
